import React, { useEffect } from 'react';
import { graphql, useStaticQuery, navigate } from 'gatsby';
// components
import Layout from '../components/layout';
import SEO from '../components/seo';
import SectionStandard from '../components/section-standard';
import HeroFullscreen from '../components/hero-fullscreen';
import HeroFullscreenSideImages from '../components/hero-fullscreen-side-images';
// services
import { trackEvent } from '../services/tracking/tracking';

const ProductsPage = () => {
  const content = useStaticQuery(graphql`
    query Products {
      directusProductsPage {
        page_title
        page_description
        hero_title
        hero_description
        hero_image {
          title
          filename_disk
        }
        section_1_title
        section_1_description
        section_1_button_label
        section_1_image {
          filename_disk
          title
        }
        section_2_title
        section_2_description
        section_2_button_label
        section_2_image {
          filename_disk
          title
        }
        section_3_title
        section_3_description
        section_3_button_label
        section_3_image {
          filename_disk
          title
        }
        section_last_title
        section_last_description
        section_last_button_label
        section_last_image_left {
          filename_disk
        }
        section_last_image_right {
          filename_disk
        }
      }
    }
  `);

  const { directusProductsPage } = content;
  const {
    page_title,
    page_description,
    hero_title,
    hero_description,
    hero_image,
    section_1_title,
    section_1_description,
    section_1_button_label,
    section_1_image,
    section_2_title,
    section_2_description,
    section_2_button_label,
    section_2_image,
    section_3_title,
    section_3_description,
    section_3_button_label,
    section_3_image,
    section_last_title,
    section_last_description,
    section_last_button_label,
    section_last_image_left,
    section_last_image_right,
  } = directusProductsPage;

  // tracking
  useEffect(() => {
    trackEvent('Product List Viewed', {
      list_id: 'Products',
      category: 'Products',
    });
  }, []);

  return (
    <Layout>
      <SEO title={page_title} description={page_description} />

      <HeroFullscreen title={hero_title} description={hero_description} image={hero_image} />

      <SectionStandard
        title={section_1_title}
        description={section_1_description}
        buttonLabel={section_1_button_label}
        buttonAction={() => navigate('/products/vitamins')}
        image={section_1_image}
      />

      <SectionStandard
        title={section_2_title}
        description={section_2_description}
        buttonLabel={section_2_button_label}
        buttonAction={() => navigate('/product/dna')}
        image={section_2_image}
        direction="Right"
      />

      <SectionStandard
        title={section_3_title}
        description={section_3_description}
        buttonLabel={section_3_button_label}
        buttonAction={() => navigate('/product/blood')}
        image={section_3_image}
      />

      <HeroFullscreenSideImages
        title={section_last_title}
        description={section_last_description}
        buttonLabel={section_last_button_label}
        buttonAction={() => navigate('/consultation')}
        imageLeft={section_last_image_left}
        imageRight={section_last_image_right}
      ></HeroFullscreenSideImages>
    </Layout>
  );
};

export default ProductsPage;
